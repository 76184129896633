@font-face {
  font-family: 'Mulish';
  font-weight: 300;
  src: url(../fonts/Mulish-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  src: url(../fonts/Mulish-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-weight: 500;
  src: url(../fonts/Mulish-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-weight: 600;
  src: url(../fonts/Mulish-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-weight: 700;
  src: url(../fonts/Mulish-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-weight: 700;
  src: url(../fonts/Mulish-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-weight: 800;
  src: url(../fonts/Mulish-ExtraBold.ttf) format('truetype');
}

body {
  font-family: Mulish, Arial, serif;
}

.diary-notes {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 409px;
}
