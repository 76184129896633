.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 36px !important;
}

.image-gallery-slide img {
  min-width: 260px;
  height: 210px;
}
.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
  border-radius: 5%;
}
